@import "../common/styles/init";

.Main {
    padding: 0 10px; }

.TextPage {
    &-Wrapper {
        padding: 0 10px;
        table {
            border-collapse: collapse;
            thead {
                text-align: center; }

            td {
                border: 1px solid currentColor;
                padding: 5px;
                text-align: left; } }

        ol {
            list-style: none;
            counter-reset: li;
            padding: 0;
            margin: 0; }

        ol > li:before {
            display: inline;
            counter-increment: li;
            content: counters(li,".") ". ";
            font-weight: bold; }

        ul {
            margin: 0; }

        li {
            text-align: justify;
            margin-bottom: 15px; }

        li span:has(table) {
            display: block;
            overflow: auto; } } }
