.Footer {
    padding: 10px 1.38889% 10px;
    max-width: 720px;
    min-width: 300px;
    line-height: 0.9;
    font-family: Arial, sans-serif;
    text-align: justify;
    font-size: 10px;
    color: #717070;
    a {
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
            color: #717070; } }

    &-LinksWrapper {
        display: flex;
        gap: 10px;
        @media (max-width: 400px) {
            flex-direction: column;
            gap: 0; } }

    &-Link {
        line-height: 1.2; } }
