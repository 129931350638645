@import "../common/styles/init";

.VideoList {
    &-Item {
        padding: 0 10px 10px;
        flex-basis: 100%;
        @include mq(tg) {
            flex-basis: 50%; } }

    &-Button {
        position: relative;
        text-decoration: none;
        width: 100%;
        background: transparent;
        padding: 0;
        border: none;
        cursor: pointer;
        &:focus {
            outline: none; } }

    &-Image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: 50% center; }

    &-Lazyload-wrapper {
        position: relative;
        border: 1px solid #DC982D;
        border-radius: 4px;
        margin-bottom: 5px;
        width: 100%; }

    &-Icon {
        width: 80px;
        height: 80px;
        position: absolute;
        z-index: 10;
        color: #cfcfcf;
        filter: drop-shadow(0 3px 3px rgba(0,0,0, .6));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

    &-Divider {
        padding-top: 100%;
        width: 100%; }

    &-List {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }

    &-Img {
        width: 100%;
        flex: 1; }

    &-Title {
        color: #fff;
        margin-top: auto;
        font-family: 'NotoSerifBold', sans-serif; }

    &-Loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 80px;
        height: 80px;
        z-index: 100; }

    &-Loader:after {
        content: '';
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border-width: 6px;
        border-style: solid;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite; }

    &-ButtonWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 0;
        z-index: 1; }

    &-More {
        position: relative;
        color: #fff;
        font-size: 2.1rem;
        font-weight: 700;
        line-height: 1;
        border: 0;
        margin: 0;
        padding: .66667em 1em;
        background-color: #d00;
        border-radius: 20px;
        vertical-align: middle;
        cursor: pointer;
        &:focus {
            outline: none; }
        &:after {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: #161a21;
            border-radius: 20px;
            left: 0;
            bottom: 2px;
            content: ''; } } }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }
