@import "../common/styles/init";

.Header {
    padding: 0 10px;
    height: 56px;
    background: linear-gradient(180deg, rgba(222,0,0,1) 0%, rgba(142,13,13,1) 50%, rgba(51,28,28,1) 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 40px;
    color: #fff;
    user-select: none;

    &-Logo {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center; }

    &-LogoImg {
        height: 37px;
        margin-right: 10px; }

    &-LogoText {
        text-shadow: 0 2px 3px rgba(0, 0, 0, .75);
        font-size: 25px; }

    &-Age {
        text-shadow: 0 2px 3px rgba(0, 0, 0, .75); } }
