@import "init";

@include font-face('tele2russiatext', '../fonts/tele2russiatext-bold', 700);
@include font-face('tele2russiatext', '../fonts/tele2russiatext-regular', 400);
@include font-face('NotoSerifBold', '../fonts/NotoSerifBold', 400);
@include font-face('Helvetica Neue', '../fonts/HelveticaNeue', 400);
@include font-face('Helvetica Neue', '../fonts/HelveticaNeue', 700);

*,
*:before,
*:after {
    box-sizing: border-box; }

html {
    height: 100%; }

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-family: tele2russiatext, Arial, sans-serif;
    background: #000;
    color: #fff;
    &.popup-open {
        overflow: hidden;
        padding-right: 17px; } }
a {
    &,
    &:link,
    &:visited {
        color: #ffffff; } }

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 720px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto; }

main {
    flex: 1 1;
    width: 100%; }

.Main {
    &-H2 {
        font-size: 28px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0;
        @include mq(md) {
            font-size: 40px; } }

    &-Wrapper {
        padding: 30px 25px 0;
        overflow: hidden;
        @include mq(md) {
            padding: 55px 40px 0; } }

    &-Button {
        display: block;
        margin-left: auto;
        margin-right: auto; } }

.swiper-button-disabled {
    opacity: .5; }

.ReactModal__Body--open {
    overflow: hidden;
    @include mq(tg) {
        padding-right: 17px; } }

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease-in-out; }

.ReactModal__Overlay--after-open {
    opacity: 1; }

.ReactModal__Overlay--before-close {
    opacity: 0; }
